/* eslint-disable react-hooks/exhaustive-deps */
import {
	Flex,
	Button,
	Span,
	Hr,
	InputContainer,
	Label,
	Input,
} from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Fragment, useState } from "react";
import { Container } from "./orders.styles";
import Nav from "../../Components/Nav";
import { useAppDispatch, useAppSelector } from "../../store/redux-hook";
import formatPrice from "../../utils/formatPrice.utils";
import { useMutation } from "@apollo/client";
import { CREATE_OFFLINE_ORDER } from "../../schemas/schemas";
import { ICart } from "../../interfaces/cart.interface";
import Error from "../../Components/ErrorComponent";
import { clearCart } from "../../store/slices/cartSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { getShopId, getTagId, getUniqueId } from "../../utils/getIds.utils";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";
import { Loader } from "../../Components/LoaderComponent/LoaderComponent";
import BottomNavbar from "../../Components/BottomNav";

const ConfirmOrder = () => {
	const { primaryColor, grey, blackLight, lightBlue, white } = Colors;
	const [comment, setComment] = useState("");

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { cart } = useAppSelector((state) => state.cart);
	const query = new URLSearchParams(useLocation().search);
	const idParam = query.get("id");

	const getOrderItems = (selectedItems: ICart[]) => {
		const orderItems = selectedItems.map((item) => {
			return {
				inventoryId: item.inventory?.inventoryId,
				variationId: item.variationId || null,
				inventoryType:
					item.inventoryType === "PIECES_AND_PACK" ? item.PiecesAndPackType : item.inventoryType,
				quantity: item.quantity,
			};
		});
		return orderItems;
	};

	const [createOfflineOrder, { loading, error }] = useMutation<any>(CREATE_OFFLINE_ORDER, {
		onCompleted() {
			dispatch(
				toggleSnackbarOpen({
					message: "Your order has been placed!",
					color: "SUCCESS",
				})
			);
			navigate("/orders");
			dispatch(clearCart());
		},
		onQueryUpdated(observableQuery) {
			return observableQuery.refetch();
		},
	});

	const createOrder = () => {		
		createOfflineOrder({
			variables: {
				orderItems: getOrderItems(cart),
				shopId: getShopId(),
				tagId: getTagId(),
				uniqueId: getUniqueId(),
				comment,
			},
		});
	};

	function totalCartAmount() {
		return cart.reduce((acc, current) => acc + current.price * current.quantity, 0);
	}

	window.onpopstate = () => {
		if (!idParam && cart.length < 1) {
			navigate("/");
		}
	};

	if (loading)
		return (
			<Flex alignItems="center" justifyContent="center" margin="50% 0 0 0" width="100%">
				<Loader width="80" />
			</Flex>
		);
	if (error)
		return (
			<Flex padding="1rem" direction="column" width="100%">
				<Nav route="/cart" title="Confirm Order" />
				<Error message={error.message} />
			</Flex>
		);

	return (
		<Container>
			<Flex direction="column">
				<Flex padding="1rem" direction="column" width="100%">
					<Nav route="/cart" title="Confirm Order" />
				</Flex>
				<Flex direction="column" padding=" 0 0 6rem 0" maxHeight="90vh" overflowY="scroll">
					<Flex width="100%" alignItems="center" direction="column" gap="20px" padding=" 0 1rem 1rem">
					{cart.map((item, i) => {
						const type = item.inventoryType;
						const name =
							type === "VARIATION"
								? `${item.inventory?.inventoryName} - '${item.variationName}'`
								: type === "PIECES_AND_PACK"
								? `${item.inventory?.inventoryName} - ${item.PiecesAndPackType}`
								: item.inventory?.inventoryName;
						return (
							<Fragment key={i}>
								<Flex
									width="100%"
									height="40px"
									justifyContent="space-between"
									alignItems="flex-start"
									direction="column">
									<Flex alignItems="center" justifyContent="space-between" width="100%">
										<Span color={grey} fontSize="0.8rem" noWrap>
											{name}
										</Span>
										<Span color={blackLight} fontSize="0.9rem">
											{formatPrice(Number(item.price))}
										</Span>
									</Flex>
									<Span fontSize="0.8rem" color={primaryColor}>
										X{item.quantity}
									</Span>
								</Flex>
								<Hr />
							</Fragment>
						);
					})}
				</Flex>
				<InputContainer>
					<Label background={white} color={lightBlue} padding="0 5px">
						Additional Instructions
					</Label>
					<Input
						height="40px"
						placeholder="E.g I don’t want much pepper"
						borderRadius="0.4rem"
						border={`2px solid ${lightBlue}`}
						padding="5px 15px"
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					/>
				</InputContainer>

				<Flex
					width="100%"
					alignItems="center"
					justifyContent="space-between"
					padding="1rem"
					direction="column">
						<Flex width="100%" alignItems="center" justifyContent="space-between" margin="1rem">
						<Span fontSize="1rem" color={primaryColor}>
							Total
						</Span>
						<Span fontWeight="500" fontSize="1.2rem" color={primaryColor}>
							{formatPrice(totalCartAmount())}
						</Span>
						</Flex>
					<Button
						height="45px"
						borderRadius="0.6rem"
						padding="3px"
						width="100%"
						fontSize="1rem"
						margin="1rem 0 0 0"
						onClick={createOrder}>
						Place Order
					</Button>
					</Flex>
				</Flex>
			</Flex>
			<BottomNavbar />
		</Container>
	);
};

export default ConfirmOrder;
