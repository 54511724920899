/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Hr, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Container, Indicator, OrderCard, OrdersContainer } from "./orders.styles";
import Nav from "../../Components/Nav";
import BottomNavbar from "../../Components/BottomNav";
import formatPrice from "../../utils/formatPrice.utils";
import { Image } from "../home/home.styles";
import Meal from "../../assets/meals-grey.svg";
import ArrowRight from "../../assets/ArrowR.svg";
import { GET_OFFLINE_MEMBER_ORDERS } from "../../schemas/schemas";
import { useQuery } from "@apollo/client";
import Error from "../../Components/ErrorComponent";
import { useEffect, useState } from "react";
import { GET_OFFLINE_MEMBER_ORDERS_TYPE, MemberOrder } from "../../interfaces/orders.interface";
import Pagination from "../../Components/Pagination";
import { getShopId, getUniqueId } from "../../utils/getIds.utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Loader } from "../../Components/LoaderComponent/LoaderComponent";

const Orders = () => {
	const {
		grey,
		primaryColor,
		skyBlue,
		blackLight,
		lightRed,
		lightOrange,
		red,
		blue,
		lightGreen,
		darkGreen,
	} = Colors;

	const [memberOrders, setMemberOrders] = useState<MemberOrder[]>([]);
	const [page, setPage] = useState(1);

	const navigate = useNavigate();
	const limit = 10;

	const { data, loading, error, refetch } = useQuery<GET_OFFLINE_MEMBER_ORDERS_TYPE>(
		GET_OFFLINE_MEMBER_ORDERS,
		{
			variables: {
				shopId: getShopId(),
				uniqueId: getUniqueId(),
				page,
				limit,
			},
		}
	);

	useEffect(() => {
		refetch();

		if (data?.getOfflineMemberOrders) {
			setMemberOrders(data.getOfflineMemberOrders.orders);
		}
	}, [data]);

	useEffect(() => {
		const interval = setInterval(() => {
			refetch();
		}, 30000);

		return () => {
			clearInterval(interval);
		};
	}, [refetch]);

	const noOrder = () => {
		return (
			<Container alignItems="center" justifyContent="flex-start">
				<Flex
					gap="1rem"
					direction="column"
					alignItems="center"
					justifyContent="center"
					width="60%"
					margin="5rem 0 0 0">
					<Image width="100px" src={Meal} />
					<Span color={blackLight} fontWeight="600" fontSize="1.2rem">
						You have no orders yet
					</Span>
					<Span textAlign="center" color={blackLight} fontSize="0.8rem">
						The order(s) you placed will appear here for you to see
					</Span>
				</Flex>
			</Container>
		);
	};

	const getStatus = (status: string): string | undefined => {
		const statusArray = [
			{ label: "PENDING", bg: lightOrange, color: primaryColor },
			{ label: "PROCESSING", bg: skyBlue, color: blue },
			{ label: "PROCESSED", bg: skyBlue, color: blue },
			{ label: "ON_DELIVERING", bg: skyBlue, color: blue },
			{ label: "CANCELLED", bg: lightRed, color: red },
			{ label: "DELIVERED", bg: lightGreen, color: darkGreen },
			{ label: "COMPLETED", bg: lightGreen, color: darkGreen },
		];
		const matchedStatus = statusArray.find((i) => i.label === status);
		return matchedStatus?.color;
	};

	const getPaymentStatus = (status: string) => {
		const statusArray = [
			{ label: "UNPAID", color: red },
			{ label: "PAID", color: darkGreen },
		];
		const matchedStatus = statusArray.find((i) => i.label === status);
		return matchedStatus?.color ?? primaryColor;
	};

	const handleClick = (order: MemberOrder) => {
		navigate(`/order-items/${order.orderId}/${order.orderNumber}`
		);
	};

	if (error)
		return (
			<Flex padding="1rem" direction="column" width="100%" alignItems="center" justifyContent="center">
				<Nav route="/" title="Home" />
				<Flex>
					<Error message={error.message} />
				</Flex>
			</Flex>
		);

	return (
		<div>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" margin="50% 0 0 0" width="100%">
					<Loader width="80" />
				</Flex>
			) : (
				<Container>
						{memberOrders?.length < 1 ? (
						noOrder()
					) : (
						<>
							<Flex width="100%" padding="1rem">
								<Nav route="/" title="Orders" />
							</Flex>
							<div className="wrapper">
								<OrdersContainer>
											{memberOrders?.map((order) => {

										return (
											<OrderCard key={order.orderNumber} onClick={() => handleClick(order)}>
												<Flex
													direction="column"
													gap="0.5rem"
													bg="transparent"
													padding="0 0 0.5rem 0">
													<Flex
														alignItems="center"
														justifyContent="space-between"
														bg="transparent">
														<Span fontSize="0.8rem" color={blackLight}>
															{`#${order.orderNumber}`}
														</Span>
														<Span fontSize="0.8rem" color={blackLight}>
															{order.OrderTags[0].Tag.tagName}
														</Span>
													</Flex>
													<Span fontSize="0.7rem" color={grey}>
														{moment(order.createdAt).format(
															"MMMM Do YYYY, h:mm a"
														)}
													</Span>
												</Flex>
												<Hr />
												<Flex
													gap="0.5rem"
													direction="column"
													bg="transparent"
													margin="0.5rem 0">
													{order.OrderItems.map(
														({ orderItemId, inventoryName, quantity, Steps }) => (
															<Flex
																key={orderItemId}
																bg="transparent"
																alignItems="center"
																gap="0.2rem"
																justifyContent="flex-start">
																<Indicator
																	color={getStatus(Steps.stepName)!}
																/>
																<Span color={blackLight} fontSize="0.8rem">
																	<em>{inventoryName}</em>
																</Span>
																<Span color={blackLight} fontSize="0.8rem">
																	<em>{`x${quantity}`}</em>
																</Span>
															</Flex>
														)
													)}
												</Flex>
												<Hr />
												<Flex
													width="100%"
													margin="0.5rem 0 0 0"
													bg="transparent"
													alignItems="center"
													justifyContent="space-between">
													<Flex
														gap="0.4rem"
														width="auto"
														alignItems="flex-end"
														bg="transparent"
														justifyContent="space-between">
														<Span
															color={blackLight}
															fontSize="0.8rem"
															fontWeight="700">
															{formatPrice(order.totalAmount)}
														</Span>
														<Span
															color={getPaymentStatus(order.paymentStatus)}
															fontSize="0.6rem">
															{order.paymentStatus.charAt(0).toUpperCase() +
																order.paymentStatus.slice(1).toLowerCase()}
														</Span>
													</Flex>
													<img width="20px" src={ArrowRight} alt="" />
												</Flex>
											</OrderCard>
										);
									})}
								</OrdersContainer>
							</div>
						</>
					)}

					{data?.getOfflineMemberOrders.total! > 0 && (
						<Pagination
							page={page}
							limit={limit}
							setPage={setPage}
							total={data?.getOfflineMemberOrders.total}
							position="fixed"
							marginBottom="2rem"
						/>
					)}
				</Container>
			)}
			<BottomNavbar />
		</div>
	);
};

export default Orders;
