import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import Nav from "../../Components/Nav";
import formatPrice from "../../utils/formatPrice.utils";
import Pending from "../../assets/pending.svg";
import PendingColor from "../../assets/pendingColor.svg";
import Processing from "../../assets/processing.svg";
import ProcessingColor from "../../assets/processingColor.svg";
import Onitsway from "../../assets/onItsway.svg";
import OnitswayColor from "../../assets/onItswayColor.svg";
import Delivered from "../../assets/delivered.svg";
import DeliveredColor from "../../assets/deliveredColor.svg";
import { IOrderItem, SINGLE_ORDER } from "../../interfaces/orders.interface";
import { Image } from "../home/home.styles";
import { formatImageUrl } from "../../utils/formatImage.utils";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Divider, Status, Wrapper } from "../order/order.styles";
import { GET_SINGLE_OFFLINE_ORDER } from "../../schemas/schemas";
import { getShopId, getUniqueId } from "../../utils/getIds.utils";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Loader } from "../../Components/LoaderComponent/LoaderComponent";

const OrderDetails: React.FC = () => {
  const { lightBlue, grey, primaryGrey } = Colors;

  const [orderItem, setOrderItem] = useState<IOrderItem>();
  const [time, setTime] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  const { id, orderNumber, orderItemId } = useParams();

  const { data, loading } = useQuery<SINGLE_ORDER>(GET_SINGLE_OFFLINE_ORDER, {
    fetchPolicy: "network-only",
    skip: !id,
    variables: {
      shopId: getShopId(),
      uniqueId: getUniqueId(),
      orderId: id,
    },
  });

  useEffect(() => {
    if (data?.getOfflineMemberSingleOrder) {
      const order = data.getOfflineMemberSingleOrder.OrderItems?.find(
        (singleOrder) => singleOrder.orderItemId === orderItemId,
      );

      setOrderItem(order);
      setTime(data.getOfflineMemberSingleOrder.createdAt);
      setComment(data.getOfflineMemberSingleOrder.comment);
    }
  }, [data, orderItemId]);

  const getStatus = () => {
    const x = [
      { name: "PENDING", name2: "", name3: "", icon: Pending, iconActive: PendingColor },
      {
        name: "PROCESSING",
        name2: "PROCESSED",
        name3: "",
        icon: Processing,
        iconActive: ProcessingColor,
      },
      { name: "ON_DELIVERING", name2: "", name3: "", icon: Onitsway, iconActive: OnitswayColor },
      {
        name: "DELIVERED",
        name2: "CANCELLED",
        name3: "COMPLETED",
        icon: Delivered,
        iconActive: DeliveredColor,
      },
    ];

    const step = orderItem?.Steps?.stepName;

    return (
      <Flex>
        <Flex
          width="100%"
          margin="1rem 0"
          alignItems="center"
          justifyContent="space-between"
          style={{ position: "relative" }}
        >
          {Array.from(x).map(({ name, name2, name3 }) => {
            const { icon: image, iconActive: imageActive } =
              x.find((img) => (img.name || img.name2) === (name || name2)) || {};
            return (
              <Flex key={`${name}-${name2}-${name3}`} alignItems="center" justifyContent="center">
                <Wrapper active={name === step || name2 === step || name3 === step}>
                  <img
                    src={name === step || name2 === step || name3 === step ? imageActive : image}
                    alt=""
                  />
                </Wrapper>
                <Status active={name === step || name2 === step || name3 === step}>
                  {name === "ON_DELIVERING" || name2 === "ON_DELIVERING"
                    ? "ON ITS WAY"
                    : name || name2}
                </Status>
              </Flex>
            );
          })}
          <Divider />
        </Flex>
      </Flex>
    );
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" margin="50% 0 0 0" width="100%">
        <Loader width="80" />
      </Flex>
    );
  }

  return (
    <Flex direction="column" padding="1rem" width="100%" maxWidth="576px">
      <Nav route={`/order-items/${id}/${orderNumber}`} title={orderItem?.inventoryName} />
      <Flex alignItems="center" justifyContent="space-between" margin="2rem 0 0 0">
        <Span color={grey} fontSize="0.8rem">
          Time of order
        </Span>
        <Span color={grey} fontSize="0.8rem">
          {moment(time).format("MMMM Do YYYY, h:mm a")}
        </Span>
      </Flex>
      <Span color={lightBlue} fontSize="1rem" margin="1.5rem 0 0 0">
        Order Details
      </Span>

      <Flex>{getStatus()}</Flex>

      <Flex
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        margin="4rem 0 0 0"
        gap="1rem"
        height="60px"
      >
        <Image
          width="100px"
          circular
          src={formatImageUrl(orderItem?.Inventory?.Images[0]?.largeImageOnlineURL)}
          alt="product image"
        />
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          direction="column"
          height="50px"
        >
          <Span fontSize="1.2rem" fontWeight="700" color={primaryGrey}>
            {orderItem?.inventoryName}
          </Span>
          <Span fontSize="1rem" fontWeight="700" color={primaryGrey}>
            {formatPrice(orderItem?.amount)}
          </Span>
        </Flex>
      </Flex>
      <Flex width="100%" direction="column" margin="2rem 0 0 0" gap="1rem">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Span color={grey} fontSize="1rem">
            Quantity
          </Span>
          <Span color={primaryGrey} fontSize="1.1rem">
            X{orderItem?.quantity}
          </Span>
        </Flex>

        <Flex width="100%" justifyContent="space-between" alignItems="flex-start" gap="1rem">
          <Span color={grey} fontSize="1rem">
            Comment:
          </Span>
          <Span color={primaryGrey} fontSize="1rem">
            {comment}
          </Span>
        </Flex>

        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Span color={primaryGrey} fontSize="1.2rem" fontWeight="700">
            Order Total
          </Span>
          <Span color={primaryGrey} fontSize="1.2rem" fontWeight="700">
            {formatPrice(orderItem?.amount)}
          </Span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OrderDetails;