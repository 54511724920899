import gql from "graphql-tag";

export const ImageSchema = `
  largeImageOnlineURL
  mediumImageOnlineURL
  smallImageOnlineURL
`;

export const ShopUrlSchema = `
  shopId
  shopTag
`;

const InventoryPricingType = `
  sellingPrice
  bargainPrice
  discountPrice
`;

export const ShopSchema = `
  shopId
  shopName
  shopAddress
  shopPhone
  distance
  latitude
  longitude
  ShopURL {
    ${ShopUrlSchema}
  }
  Images {
    ${ImageSchema}
  }
`;

export const InventorySchema = `
  inventoryName
  inventoryDescription
  inventoryId
  quantityInPacks
  quantityInPieces
  trackable
  isVariation
  quantity
  inventoryType
  Images {
    ${ImageSchema}
  }
  TrackableItem {
    unitPiecesCostPrice
    unitPackCostPrice
    perPack
    unitPrice
    packPrice
  }
  NonTrackableItem {
    costPrice
    sellingPrice
    fixedSellingPrice
  }
  InventoryPrice {
    PIECES {
      ${InventoryPricingType}
    }
    PACK {
      ${InventoryPricingType}
    }
    NON_TRACKABLE{
      ${InventoryPricingType}
    }
    VARIATION {
      variationId
      price {
        ${InventoryPricingType}
      }
    }
}
  Variations {
    variationId
    variationName
    inventoryId
    price
    cost
    shopId
  }
  Shop {
    ${ShopSchema}
  }
  InventoryUrl {
    inventoryUrlId
    shopId
    inventoryId
    url
  }
  InventoryQuantity{
    inventoryId
    variationId
    quantity
  }
`;

const singleOrderSchema = `
      OrderItems {
        Steps {
          stepId
          stepName
        }
        orderItemId
        inventoryName
        Inventory {
          ${InventorySchema}
                }
        inventoryType
        orderId
        itemName
        amount
        quantity
      }
      comment
      createdAt
      totalAmount
`;

export const GET_SHOP_CATEGORIES_SCHEMA = gql`
  query getShopCategories {
    getShopCategories {
      shopCategoryId
      shopCategoryName
      description
    }
  }
`;

export const GET_ALL_SHOP_INVENTORY_CATEGORY_SCHEMA = gql`
  query getAllShopInventoryCategory($shopId: ID, $shopTag: String) {
    getAllShopInventoryCategory(shopId: $shopId, shopTag: $shopTag) {
      inventoryCategoryId
      inventorycategoryName
    }
  }
`;

export const GET_ALLSHOP_OFFLINE_INVENTORIES_SCHEMA = gql`
query getAllShopOfflineInventories(
		$shopId: String!,
		$searchString: String,
		$filterByInStock: Boolean,
		$categoryId: String,
		$page: Int,
		$limit: Int,
	){
	getAllShopOfflineInventories(
		searchString: $searchString
		shopId: $shopId
		filterByInStock: $filterByInStock	
		categoryId: $categoryId
		page: $page
		limit: $limit) {	
		inventories {
      ${InventorySchema}
    }
    total
		}
	}
`;

export const CREATE_OFFLINE_ORDER = gql`
  mutation CreateOfflineOrder(
    $uniqueId: String!
    $shopId: String!
    $tagId: String!
    $orderItems: [JSONObject!]!
    $comment: String
  ) {
    createOfflineOrder(
      uniqueId: $uniqueId
      shopId: $shopId
      tagId: $tagId
      orderItems: $orderItems
      comment: $comment
    ) {
      successful
    }
  }
`;

export const GET_OFFLINE_MEMBER_ORDERS = gql`
	query GetOfflineMemberOrders($uniqueId: String!, $shopId: String!, $limit: Int, $page: Int) {
		getOfflineMemberOrders(uniqueId: $uniqueId, shopId: $shopId, limit: $limit, page: $page) {
      orders {
        orderId
        orderNumber
        ownerMemberId
        subTotal
        totalAmount
        paymentStatus
        paymentMethod
        deliveryOption
        deliveryFee
        transactionFee
        otherDetails
        comment
        Shop {
          shopName
          ShopURL {
            shopTag
          }
        }
        updatedAt
        createdAt
         OrderTags {
        orderTagId
        orderId
        shopId
        tagId
        Tag {
          tagId
          shopId
          tagName
          uniqueId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
        OrderItems {
          Steps {
            stepId
            stepName
          }
          orderItemId
          inventoryName
          Inventory {
                      ${InventorySchema}
                  }
          inventoryType
          orderId
          itemName
          amount
          quantity
        }
      }
      total
		}
	}
`;

export const GET_SHOP_SCHEMA = gql`
	query GetShop($shopId: ID, $shopStatus: ShopStatus) {
		getShop(shopId: $shopId, shopStatus: $shopStatus) {
			${ShopSchema}
		}
	}
`;

export const CALL_ATTENDANT = gql`
  mutation CallAttendant($tagId: String!) {
    callAttendant(tagId: $tagId)
  }
`;

export const GET_SINGLE_OFFLINE_ORDER = gql`
query GetOfflineMemberSingleOrder($uniqueId: String!, $shopId: String!, $orderId: ID!) {
  getOfflineMemberSingleOrder(uniqueId: $uniqueId, shopId: $shopId, orderId: $orderId) {
    ${singleOrderSchema}
  }
}
`;