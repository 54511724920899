import { Text, Flex, Span, Hr, CountButton, Button } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Container } from "./cart.styles";
import Nav from "../../Components/Nav";
import { useAppDispatch, useAppSelector } from "../../store/redux-hook";
import BottomNavbar from "../../Components/BottomNav";
import RedClose from "../../assets/red-close.svg";
import { Image } from "../home/home.styles";
import formatPrice from "../../utils/formatPrice.utils";
import { decQty, incQty, removeFromCart } from "../../store/slices/cartSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { BuyType } from "../../interfaces/cart.interface";
import { Fragment, useState } from "react";
import EmptyCart from "../../assets/prev-cart.svg";
import { NavLink } from "react-router-dom";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";

const Cart = () => {
	const { primaryColor, grey4, black } = Colors;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const query = new URLSearchParams(useLocation().search);
	const confirm = query.get("p");

	const [proceed, setProceed] = useState(false);
	const show = proceed && confirm;

	const { cart } = useAppSelector((state) => state.cart);

	function totalCartAmount() {
		return cart.reduce((acc, current) => acc + current.price * current.quantity, 0);
	}

	const handleQuantity = (type: string, id: string, buyType?: BuyType) => {
		type === "inc" ? dispatch(incQty({ id, buyType })) : dispatch(decQty({ id, buyType }));
	};

	const handleRemoveFromCart = (id: string, buyType: BuyType) => {
		dispatch(removeFromCart({ id, buyType }));
		dispatch(
			toggleSnackbarOpen({
				message: "Item removed from cart!",
				color: "SUCCESS",
			})
		);
	};

	return (
		<Container>
			{!show && (
				<Flex direction="column" padding="1rem" width="100%">
					<Flex padding=" 0 0 1rem 0" direction="column" width="100%">
						<Nav route="/" title="My Cart" />
					</Flex>
					{cart.length < 1 ? (
						<Flex
							margin="5rem 0 0 0"
							gap="2rem"
							alignItems="center"
							justifyContent="center"
							direction="column">
							<img src={EmptyCart} alt="empty cart" style={{ width: "360px" }} />
							<Text fontSize="1.5rem" alignSelf="center" textAlign="center">
								Add some items to cart then try again.
							</Text>
							<NavLink className="back-home" to="/">
								Go Back
							</NavLink>
						</Flex>
					) : (
						<Flex
							width="100%"
							alignItems="center"
							direction="column"
							gap="10px"
							maxHeight="55vh"
							overflowY="scroll"
							padding="0 10px 0 0"
							margin="0 0 2rem 0">
							{cart.map((item, i) => {
								const type = item.inventoryType;
								const id =
									type === "VARIATION" ? item.variationId : item.inventory?.inventoryId;

								const name =
									type === "VARIATION"
										? `${item.inventory?.inventoryName}-'${item.variationName}'`
										: item.inventory?.inventoryName;

								const cartItem = cart.find(
									(foo) =>
										foo.inventory?.inventoryId === id &&
										foo.PiecesAndPackType === item.PiecesAndPackType
								);

								return (
									<Fragment key={i}>
										<Flex
											width="100%"
											height="70px"
											justifyContent="space-between"
											alignItems="center" style={{
												overflowX: "hidden",
												minHeight: "70px"
											}}
										>

											<Flex alignItems="center" justifyContent="flex-start" width="75%" style={{ overflowX: "hidden" }}>
												<Image
													src={RedClose}
													width="18px"
													onClick={() =>
														handleRemoveFromCart(
															id!,
															cartItem?.PiecesAndPackType!
														)
													}
												/>
												<Span color={black} fontSize="0.9rem" margin="0 0 0 1rem" noWrap>
													{name}
													{item.inventoryType === "PIECES_AND_PACK" &&
														` - ${item.PiecesAndPackType}`}
												</Span>
											</Flex>

											<Flex direction="column" gap="5px" height="fit-content" width="20%">
												<Text fontWeight="600" fontSize="0.9rem" alignSelf="end">
													{formatPrice(Number(item.price))}
												</Text>
												<Flex
													bg="transparent"
													justifyContent="space-between"
													alignItems="center"
													gap="10px"
													width="100%"
													alignSelf="end">
													<CountButton
														disabled={item.quantity <= 1}
														height="25px"
														borderRadius="4px"
														padding="3px"
														width="33%"
														fontSize="1.3rem"
														onClick={() =>
															handleQuantity(
																"dec",
																id!,
																cartItem?.PiecesAndPackType
															)
														}>
														-
													</CountButton>
													<Span fontWeight="700">{item.quantity}</Span>
													<CountButton
														height="25px"
														borderRadius="4px"
														padding="3px"
														width="33%"
														fontSize="1.3rem"
														onClick={() =>
															handleQuantity(
																"inc",
																id!,
																cartItem?.PiecesAndPackType
															)
														}>
														+
													</CountButton>
												</Flex>
											</Flex>

										</Flex>
										<Hr />
									</Fragment>
								);
							})}
						</Flex>
					)}
					{cart.length > 0 && (
						<>
							<Flex
								width="100%"
								alignItems="center"
								justifyContent="space-between"
								margin="0 0 1rem 0">
								<Span fontSize="0.8" color={grey4}>
									Subtotal
								</Span>
								<Span color={grey4}> {formatPrice(totalCartAmount())}</Span>
							</Flex>
							<Flex width="100%" alignItems="center" justifyContent="space-between">
								<Span fontSize="1rem" color={primaryColor}>
									Total
								</Span>
								<Span fontWeight="500" fontSize="1.2rem" color={primaryColor}>
									{formatPrice(totalCartAmount())}
								</Span>
							</Flex>

							<Button
								disabled={cart.length < 1}
								height="45px"
								margin="2rem 0 5rem 0"
								fontSize="1rem"
								backgroundColor={primaryColor}
								color="white"
								width="100%"
								padding="3px 6px"
								borderRadius="10px"
								onClick={() => {
									setProceed(true);
									navigate("/confirm-order");
								}}>
								Proceed
							</Button>
						</>
					)}
				</Flex>
			)}
			<BottomNavbar />
		</Container>
	);
};

export default Cart;
